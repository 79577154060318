<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    class="fill-background stroke-neutral-900 dark:stroke-neutral-50"
  >
    <path
      v-for="(face, index) in sortedFaces"
      :key="index"
      :d="getFacePath(face.vertices)"
      stroke-width="1.5"
      stroke-linejoin="round"
      stroke-linecap="round"
    />
  </svg>
</template>

<script lang="ts" setup>
import * as d3 from 'd3'

withDefaults(
  defineProps<{
    size?: number
  }>(),
  {
    size: 32,
  },
)

const { x, y } = useMouse()
const initialRotationX = Math.atan(1 / Math.sqrt(2))
const initialRotationY = Math.PI / 4
const rotationX = ref(initialRotationX)
const rotationY = ref(initialRotationY)
const vertices = [
  [-1, -1, -1],
  [1, -1, -1],
  [1, 1, -1],
  [-1, 1, -1],
  [-1, -1, 1],
  [1, -1, 1],
  [1, 1, 1],
  [-1, 1, 1],
]
const faces = [
  [0, 1, 2, 3], // Back face
  [4, 5, 6, 7], // Front face
  [0, 1, 5, 4], // Bottom face
  [2, 3, 7, 6], // Top face
  [0, 3, 7, 4], // Left face
  [1, 2, 6, 5], // Right face
]
const transformedVertices = computed(() =>
  vertices.map((v) => rotateX(rotateY(v, rotationY.value), rotationX.value)),
)
const sortedFaces = computed(() =>
  faces
    .map((face) => ({
      vertices: face.map((idx) => transformedVertices.value[idx]),
      originalIndices: face,
    }))
    .sort((a, b) => getFaceDepth(b.vertices) - getFaceDepth(a.vertices)),
)
const throttledUpdateRotation = useThrottleFn(updateRotation, 75)

function rotateX(point: number[], angle: number) {
  const [x, y, z] = point
  const cos = Math.cos(angle)
  const sin = Math.sin(angle)
  return [x, y * cos - z * sin, y * sin + z * cos]
}

function rotateY(point: number[], angle: number) {
  const [x, y, z] = point
  const cos = Math.cos(angle)
  const sin = Math.sin(angle)
  return [x * cos + z * sin, y, -x * sin + z * cos]
}

function project(point: number[]) {
  const scale = (24 - 1.5) / Math.sqrt(3 * Math.pow(2, 2))
  return [point[0] * scale + 24 / 2, point[1] * scale + 24 / 2]
}

function getFaceDepth(face: number[][]) {
  return face.reduce((sum, vertex) => sum + vertex[2], 0) / face.length
}

function getFacePath(faceVertices: number[][]) {
  return (
    faceVertices
      .map((vertex, i) => {
        const [x, y] = project(vertex)
        return `${i === 0 ? 'M' : 'L'}${x},${y}`
      })
      .join(' ') + 'Z'
  )
}

function updateRotation() {
  const targetRotationX =
    initialRotationX +
    ((y.value - window.innerHeight / 2) / window.innerHeight) * Math.PI
  const targetRotationY =
    initialRotationY +
    ((window.innerWidth / 2 - x.value) / window.innerWidth) * Math.PI

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  d3.select({} as any)
    .transition()
    .duration(1250)
    .ease(d3.easeQuadOut)
    .tween('rotation', () => {
      const interpolateX = d3.interpolate(rotationX.value, targetRotationX)
      const interpolateY = d3.interpolate(rotationY.value, targetRotationY)

      return (t: number) => {
        rotationX.value = interpolateX(t)
        rotationY.value = interpolateY(t)
      }
    })
}

watch([x, y], throttledUpdateRotation)
</script>
